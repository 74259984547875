import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { postRegister, fetchRegisterAttributes } from "../../../api/api";
import Loading from "../../../components/Loading";
import Form from "../../auth/register/parts/Form";
import logo from "../../../assets/images/logo_lo_color.png";
import { Notify } from "../../../utils/notify";

export default function Register() {
    const [isLoading, setLoading] = useState(true);
    const [attributes, setAttributes] = useState([]);

    const registerMutation = useMutation((values) => postRegister(values));

    useQuery(["registerAttributes"], () => fetchRegisterAttributes(), {
        onSuccess: (data) => {
            setAttributes(data);
            setLoading(false);
        },
        onError: (error) => {
            setLoading(false);
        },
    });

    const onSubmit = (values, setSubmitting, setErrors) => {
        setSubmitting(true);
        registerMutation.mutate(values, {
            onSuccess: (data) => {
                setSubmitting(false);
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "sign_up",
                });
            },
            onError: (error) => {
                //Returns validation errors, but registration is paged so it's not very useful
                if (error.response?.data.errors) {
                    setErrors(error.response.data.errors);
                }
                Notify.error("Het registreren is mislukt, probeer het later nogmaals.");

                setSubmitting(false);
            },
        });
    };

    return (
        <div>
            <img className="logo" src={logo} alt="logo" />
            <h1>Aanmelden</h1>
            {isLoading ? (
                <Loading />
            ) : registerMutation.isSuccess ? (
                <p>
                    Je aanmelding is gelukt. Welkom bij het Landelijk Ouderpanel!
                    <br />
                    <br />
                    Binnen enkele minuten ontvang je een e-mail om je account te activeren. Geen e-mail ontvangen? Controleer je spam folder.
                    <br />
                    <br />
                    Heb je je telefoonnummer opgegeven? Dan ontvang je ook een sms om je telefoonnummer te verifiëren.
                    <br />
                    <br />
                    Gaat er iets mis?{" "}
                    <a href="https://oudersenonderwijs.nl/contact/" target="_blank">
                        Neem dan contact op met Ouders & Onderwijs
                    </a>
                    .
                </p>
            ) : (
                <Form pagedAttributes={attributes} onSubmit={onSubmit} />
            )}
        </div>
    );
}
